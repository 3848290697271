











































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'uOttawa1321Prelab6Q6',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswer: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {
          text: 'To heat less so the solvent does not evaporate, and the volume of solvent remains constant',
          value: 'heatLess',
        },
        {
          text: 'To heat more because the reaction is highly endothermic',
          value: 'heatMore',
        },
        {
          text: 'To heat gradually less and less as the water cools down',
          value: 'graduallyLess',
        },
        {
          text: 'The heat applied is more equally distributed with a water bath',
          value: 'equally',
        },
        {
          text: 'Because the solvent used in the reaction is flammable',
          value: 'flammable',
        },
      ],
      optionsFr: [
        {
          text: "Pour chauffer moins afin que le solvant ne s'évapore pas et que le volume de solvant demeure constant",
          value: 'heatLess',
        },
        {
          text: 'Pour chauffer plus parce que cette réaction est grandement endothermique',
          value: 'heatMore',
        },
        {
          text: "Pour chauffer de moins en moins à mesure que l'eau refroidit",
          value: 'graduallyLess',
        },
        {
          text: 'Pour appliquer une chaleur qui est plus uniforme',
          value: 'equally',
        },
        {
          text: 'Parce que le solvant utilisé pour la réaction est inflammable',
          value: 'flammable',
        },
      ],
    };
  },
};
